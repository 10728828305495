<template>
    <div>
        <program-list />
    </div>
</template>
<script>
import ProgramList from "common/views/ProgramList.vue";

export default {
	components: {
		ProgramList
	}
};
</script>