<template>
	<div>
		<div v-if="$store.getters.isCourseSelector" class="container courseselectortitle">
			<h1>COURSE SELECTOR</h1>
			<p><a :href="$root.formerCourseSelectorUrl">{{$t("courseselector.formerCourseSelectorLinkText")}}</a></p>
		</div>
		<div class="home container gutter-hva">
			<div class="row" v-if="$store.getters.isStudiegids">
				<div class="col offset-md-3">
					<h2 class="header page-header">{{ $t('programList.header')}}</h2>
					<div class="shareEditor" v-if="userIsEditor">
						<font-awesome-icon  icon="share-alt" :title="$t('general.shareEditorPage')" :alt="$t('general.shareEditorPage')" @click="getEditorShareUrl" />
					</div>
					<div class="download-excel" v-if="userCanEditPrograms">
							<font-awesome-icon v-show="!downloading" icon="file-excel" :title="$t('programList.excel.export')" :alt="$t('programList.excel.export')" @click="exportToExcel" />
							<font-awesome-icon v-show="downloading" icon="sync" spin />
						</div>
					<span class="bi-statistics" v-if="userCanEditPrograms" @click="bus.$emit('showDetails')"><font-awesome-icon class="show-chart" icon="chart-bar" :title="$t('programList.showAllStates')" :alt="$t('programList.showAllStates')" /></span>
				</div>
			</div>
			<div class="row">
				<div class="d-none d-md-block col-3">
					<div v-if="!loading && programData.length > 0">
						<h3 class="header filter-header">{{$t('programList.filter')}}</h3>
						<ul class="filters list-group list-group-flush">
							<!-- Academic Year radio button -->
							<li class="list-group-item">
								<span>{{$t('programList.academicYear')}}</span>
								<select class="subfilter" v-model="selectedYear" @change="populateData().then(filterResults(true))">
									<option v-for="year in years" :key="year">{{year}}</option>
								</select>
							</li>
							<!-- Filter options (both header and checkboxes) -->
							<li class="list-group-item" v-for="key in filterKeys" :key="key">
								<span v-if="JSON.stringify(filters[key]).includes('Name')">{{$t('programList.'+key.toLowerCase())}}</span>
								<div v-for="(filter, index) in sortedFilters(filters[key])" :key="index">
									<div v-if="filter.Name" class="subfilter" >
										<div class="form-check subfilter-form">
											<input class="form-check-input" type="checkbox" :id="filter.Name" v-model="filter.Active" @change="filterResults(true)">
											<label class="subfilter-label form-check-label" :for="filter.Name">
												<span>{{filter.Name}}</span>
												<small v-show="filter.Count > 0">({{filter.Count}})</small>
											</label>
											<div v-if="isExchange && key== 'Faculty' && filter.Name == 'Digital Media and Creative Industries'">
										<ul class="list-group list-group-flush">
											<li v-for="(fdmciGroup, index2) in sortedFilters(fdmciGroups)" :key="index2">
												<div class="form-check subfilter-form">
												<input class="form-check-input" type="checkbox" :id="fdmciGroup.Name" v-model="fdmciGroup.Active" @change="filterResults(true)">
												<label class="subfilter-label form-check-label" :for="fdmciGroup.Name">
													<span>{{fdmciGroup.Name}}</span>
													<small v-show="fdmciGroup.Count > 0">({{fdmciGroup.Count}})</small>
												</label>
												</div>
											</li>
										</ul>
										</div>
										</div>
										
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div :class="[isExchange ? 'exchangeWrapper': '', 'col']">
					<CourseSearch v-if="userIsEditor && !$store.getters.isCourseSelector" />
					<!-- Introduction text -->
					<div v-if="!$store.getters.isOnderwijslijst && !$store.getters.isCourseSelector" class="introduction">
						<div v-if="!isExchange && content && content.MainInfo" v-html="content.MainInfo.details.Text"></div>
						<div v-if="isExchange && content && content.MainInfoExchangeProgram" v-html="content.MainInfoExchangeProgram.details.Text"></div>
					</div>
					<!-- Search box -->
					<b-input-group class="input-group mb-3 search2">
						<b-form-input @keydown="preventEnter" class="search-text" type="text" v-model="searchString" />
						<button type="button" class="search-button"><font-awesome-icon icon="search"/></button>
					</b-input-group>
					<div v-if="!loading && programData.length > 0">
						<!-- Results -->
						<h3 class="header programs-header">{{programCount}} {{$tc('programList.summary', programCount)}}</h3>
						<ul class="program-groups list-group list-group-flush">
							<template v-if="!isExchange">
								<li class="list-group-item list-group-flush" v-for="(group, key, index) in programGroups" :key="index">
									<strong class="program-group">{{ key }}</strong>
									<ul class="programs list-group" v-for="item in group" :key="item.Id">
										<li class="list-group-item">
											<StatusRow :bus="bus" v-if=userCanEditProgram(item) :program="item" @exceedanceClicked="exceedanceClicked"></StatusRow>
											<a v-else-if="$store.getters.isOnderwijslijst" class="program-link hva-caret-right" :href="item.LinkToProgram">
												<span v-html="itemHTML(item)" />
											</a>
											<router-link v-else-if="$store.getters.isCourseSelector" class="program-link hva-caret-right" :to="programRouteForCourseSelector(item)">
												<span v-html="itemHTML(item)" />
											</router-link>
											<a v-else-if="item.ShowInEnglish && !userIsProgramEditor && !userIsProgramFinalEditor && !userIsCourseEditor && !userIsCourseFinalEditor" class="program-link hva-caret-right" :href="englishCourseCatalogUrl + item.Slug + '/' + selectedYear">
												<span v-html="itemHTML(item)" />
											</a>
											<router-link v-else class="program-link hva-caret-right" :to="programRoute(item)">
												<span v-html="itemHTML(item)" />
											</router-link>
										</li>
									</ul>
								</li>
							</template>
							<template v-else> <!--Note: copy from the li above-->
								<li class="list-group-item" v-for="(item, key) in programsForExchange" :key="key + '1'">
									<StatusRow :bus="bus" v-if=userCanEditProgram(item) :program="item" @exceedanceClicked="exceedanceClicked"></StatusRow>
									<a v-else-if="$store.getters.isOnderwijslijst" class="program-link hva-caret-right" :href="item.LinkToProgram">
										<span v-html="itemHTML(item)" />
									</a>
									<router-link v-else-if="$store.getters.isCourseSelector" class="program-link hva-caret-right" :to="programRouteForCourseSelector(item)">
										<span v-html="itemHTML(item)" />
									</router-link>
									<a v-else-if="isPreview(item)" class="program hva-caret-right">
										<span v-html="itemHTML(item)"></span>
										<span>&nbsp;{{$tc('programList.comingSoon')}}</span>
									</a>
                                    <router-link v-else-if="item.ShowInEnglish && !userIsProgramEditor && !userIsProgramFinalEditor && !userIsCourseEditor && !userIsCourseFinalEditor" class="program-link hva-caret-right" :to="programRoute(item)">
										<span v-html="itemHTML(item)" />
									</router-link>
									<router-link v-else class="program-link hva-caret-right" :to="programRoute(item)">
										<span v-html="itemHTML(item)" />
									</router-link>
								</li>
							</template>
						</ul>
					</div>
					<div v-else-if="!loading">{{ $t('programList.loadFailed') }} <a href="javascript:void(0);" @click="refreshPage">{{ $t('programList.loadFailedLink') }}</a></div>
					<spinner v-else />
					<warningModal v-if="showExceedanceModal" @close="showExceedanceModal = false" :details="details" :title="warningProgramTitle" :slug="warningProgramSlug" :acYear="selectedYear"></warningModal>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-console */
import Fuse from "fuse.js";
import { mapState } from "vuex";
import Spinner from "../components/General/Spinner.vue";
import CourseSearch from "../components/CourseSearch"
import EditorialState from "../mixins/EditorialState";
import settings from "../assets/settings.json";
import StatusRow from "../components/StatusRow"
import warningModal from "../components/WarningModal.vue";
import Vue from "vue";
import XLSX from "xlsx";
import { get } from "common/store/storeFunctions";

export default {
	name: "program-list",
	components: {
        Spinner,
		CourseSearch,
		StatusRow,
		warningModal
	},
	mixins:[EditorialState],
	data() {
		return {
			years: null,
			selectedYear: null,
			currentYear: null,
			programData: null,
			programGroups: {},
			programsForExchange: [],
			programCount: 0,
			filters: {},
			filterKeys: ["Faculty",  "Variant", "Level"],
			fdmciGroups: {},
			searchString: "",
			searchFuse: null,
			searchOptions: null,
			loading: true,
			downloading: false,
			programDetails: null, //for excel export
			details: null, //for warning modal
			warningProgramTitle: null,  //for warning modal
			warningProgramSlug: null, //for warning modal
			showExceedanceModal: false, //for warning modal
			bus: new Vue(), //Used for talking with child components
			deepLink: null,
			firstload: true
		};
	},
	computed: {
		archiveLink() {
			return this.language == 1033 ? "/archive" : "/archief";
		},
		englishCourseCatalogUrl() {
			var host = location.host;
			if (host.indexOf("exchange") != -1 || this.isExchange){
				return "/";
			} else if (host.indexOf("dev-") != -1) {
				return "https://dev-coursecatalogue.mijnhva.nl/";
			} else if (host.indexOf("acc-") != -1) {
				return "https://acc-coursecatalogue.mijnhva.nl/";
			}else{
				return "https://coursecatalogue.amsterdamuas.com/";
			}
		},
		userIsEditor(){
			return this.userIsProgramEditor || this.userIsProgramFinalEditor || this.userIsCourseEditor || this.userIsCourseFinalEditor;
		},
		userCanEditPrograms(){
			return  (this.userIsProgramEditor || this.userIsProgramFinalEditor);
		},
		powerBILink(){
			return settings.powerBILink;
		},
		...mapState(["programs", "content", "acYears"])
	},
	watch: {
		searchString: function(newSearch) {
			this.filterResults(false, newSearch);
		},
		programGroups: function(programGroups){
			var model = this;
			if(!this.isExchange){
				return [];
			} else {
				var sorted = [];
				var keys = Object.keys(programGroups);
				keys.forEach(function(key){
					programGroups[key].forEach(function(program){
						sorted.push(program);
					})
				})
				sorted.sort((a, b) => (a.Title > b.Title) ? 1 : -1);
				model.programsForExchange = sorted;
			}
		},
		"$route.params.deepLink": async function (deepLink){
			if (!this.firstLoad) {
				this.deepLink = deepLink;
				await this.populateData();
			}
		}
	},
	async created() {
		if (this.$route.params.deepLink) {
			this.deepLink = this.$route.params.deepLink;
		}
		//https://fusejs.io/ for info (optional TODO: update to Vue variant: https://www.npmjs.com/package/vue-fuse)
		this.searchOptions = {
			shouldSort: true,
			threshold: 0.1,
			location: 0,
			distance: 1000,
			maxPatternLength: 32,
			minMatchCharLength: 1,
			keys: ["Title", "Group", "Faculty", "Variant", "Level", "Slug"]
		};
		if(!this.$store.acYearsHaveBeenSet){
			await this.$store.dispatch("getAcademicYears");
		}
		this.years = this.acYears.years;
		this.selectedYear = this.acYears.acYearToSelect;
		if(this.deepLink){
			var deepLinkObject = JSON.parse(this.deepLink);
			if(deepLinkObject.studiejaar){
				this.selectedYear = deepLinkObject.studiejaar;
			}
		}
		this.currentYear = this.acYears.currentAcademicYear;
        await this.$store.dispatch("getPrograms", this.selectedYear).catch(err => { console.log(err); });
		if (this.isExchange){
			this.filterKeys = ["Faculty",  "Variant", "Level", "Semester"];
			await this.$store.dispatch("getContent", 'MainInfoExchangeProgram');
		}else{
			await this.$store.dispatch("getContent", 'MainInfo');
		}
		this.populateData();
		this.loading = false;
		this.firstLoad = false;
	},
	methods: {
		getEditorShareUrl(){
			this.$root.$children[0].getEditorShareUrl();
		},
		sortedFilters(filter) {
			var cfilter = [];
			Object.keys(filter).forEach(element => {
				cfilter.push(filter[element]);
			});
			cfilter.sort((a, b) => {
				if(!a.Name) return a > b ? 1 : -1;
				return a.Name > b.Name ? 1 : -1;
			});
			return cfilter;
		},
		userCanEditProgram(program){
			if(program === undefined || program === null){
				return false;
			}
			var facultyId = program.FacultyId;
			var facultyProgramEditor = false;
			if (this.isExchange && ((this.roles.ExchangeProgramProgramEditor && this.roles.ExchangeProgramProgramEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramProgramFinalEditor && this.roles.ExchangeProgramProgramFinalEditor.indexOf(facultyId) != -1))){
				facultyProgramEditor = true;	
			}
			if (!this.isExchange && ((this.roles.CourseCatalogProgramEditor && this.roles.CourseCatalogProgramEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogProgramFinalEditor && this.roles.CourseCatalogProgramFinalEditor.indexOf(facultyId) != -1))){
				facultyProgramEditor = true;				
			}
			return facultyProgramEditor && program.Editable && this.userCanEditPrograms
		},
		populateData: async function() {
			var model = this;
			model.filters = {};
			model.loading= true;
			model.programData = [];
			if (this.programs[model.selectedYear] === undefined) {
				await this.$store.dispatch("getPrograms", model.selectedYear).catch(err => {console.log(err);});
			}
			if (this.programs[model.selectedYear] !== undefined) {
				this.programs[model.selectedYear].forEach(function(program) {
					for(var i = 0; i < program.ProgramGroupings.length; i++){
						var group = i;
						var processedData = {};
						processedData.Title = program.Title;
						processedData.Id = program.Id;
						processedData.Group = program.ProgramGroupings[group];
						processedData.Faculty = program.Faculty;
						processedData.Faculties = program.Faculties !== undefined ? program.Faculties : [] ;
						processedData.FacultyId = program.FacultyId;
						processedData.Variant = program.ModeOfStudy;
						processedData.Level = program.Level;
						processedData.Editable = (program.Editable !== undefined || program.Editable === true);
						processedData.EditorialState = program.Info.EditorialState;
						if (model.$store.getters.isStudiegids) {
							processedData.Slug = program.Slug;
						}
						if(model.isExchange){
							processedData.Semester = program.Semester;
							processedData.ExchangeGroup = program.ExchangeGroup;
							processedData.ExchangeGroups = program.ExchangeGroups !== undefined ? program.ExchangeGroups : [] ;
						}
						if(program.ShowInEnglish === undefined){
							processedData.ShowInEnglish = false;
						}else{
						processedData.ShowInEnglish = program.ShowInEnglish;
						}
						if (model.$store.getters.isOnderwijslijst) {
							processedData.LinkToProgram = program.LinkToProgram;
						}
						var validData = [];
						for (var key in processedData) {
							if(key != "Semester" && key != "ExchangeGroup"){
								if (processedData[key] == null) 
									validData.push(key);
							}
						}
						if (validData.length == 0) {
							model.programData.push(processedData);
						} else if (model.userCanEditPrograms) {
							console.log(program.Title + " is omitted due to missing values:\n" + validData.join(", "));
						}

						if(model.isExchange) {
							// Never show programs twice in the exchange catalogue
							// because group headers are not shown 
							break;
						}
					}
				});
			}

			model.programData.forEach(function(program) {
				model.programData.sort();
				model.filterKeys.forEach(function(key) {
					if (!model.filters[key]) {
						model.filters[key] = {};
					}
					if (program[key] && !model.filters[key][program[key]]) {
						model.filters[key][program[key]] = { Active: false, Count: 0, Name: program[key] };
						if (model.deepLink){
							if (model.deepLink.indexOf(model.filters[key][program[key]].Name) !== -1){
								model.filters[key][program[key]].Active = true;
							}
						}  
					}
					if(key == "Faculty") {
						program.Faculties.forEach((value) => {
						if(!model.filters[key][value])
							model.filters[key][value] = { Active: false, Count: 0, Name: value };
						if (model.deepLink){
							if (model.deepLink.indexOf(model.filters[key][value].Name) !== -1){
								model.filters[key][value].Active = true;
							}
						}  
						});
					}
					if(model.isExchange && key == "Faculty" && program[key] == "Digital Media and Creative Industries"){
						if(!model.fdmciGroups[program.ExchangeGroup] && program.ExchangeGroup){
							model.fdmciGroups[program.ExchangeGroup] = { Active: false, Count: 0, Name: program.ExchangeGroup };
							if (model.deepLink){
								if (model.deepLink.indexOf(model.fdmciGroups[program.ExchangeGroup].Name) !== -1){
									model.fdmciGroups[program.ExchangeGroup].Active = true;
								}
							}  
						}
						program.ExchangeGroups.forEach((value) => {
							if(!model.fdmciGroups[value]){
								model.fdmciGroups[value] = { Active: false, Count: 0, Name: value };
								if (model.deepLink){
									if (model.deepLink.indexOf(model.fdmciGroups[value].Name) !== -1){
										model.fdmciGroups[value].Active = true;
									}
								}  
							}
						});
					}
				});
			});

			model.searchFuse = new Fuse(model.programData, model.searchOptions);
			model.filterResults(false);
			model.loading = false;
		},
		filterResults: function(isFilterChange) {
			var model = this;
			var results = model.stringIsNullOrEmpty(model.searchString) ? model.programData : model.searchFuse.search(model.searchString);

			var activeFilters = {
				studiejaar: this.selectedYear
			};
			for (var key in model.filters) {
				for (var filter in model.filters[key]) {
					model.filters[key][filter].Count = 0;
					if (model.filters[key][filter].Active) {
						if (activeFilters[key]) {
							activeFilters[key].push(filter);
						} else {
							activeFilters[key] = [];
							activeFilters[key].push(filter);
						}
					}
				}
			}
			if(model.isExchange){
				for (var fdmciGroup in model.fdmciGroups) {
					model.fdmciGroups[fdmciGroup].Count = 0;
					if (model.fdmciGroups[fdmciGroup].Active) {
						if (activeFilters["ExchangeGroup"]) {
							activeFilters["ExchangeGroup"].push(fdmciGroup);
						} else {
							activeFilters["ExchangeGroup"] = [];
							activeFilters["ExchangeGroup"].push(fdmciGroup);
						}
					}
				}
			}

			if (isFilterChange) {
				if (JSON.stringify(activeFilters) !== "{}" && JSON.stringify(activeFilters) !== this.deepLink) {
					var deeplink = JSON.stringify(activeFilters)
					this.$router.push({
						name: "homeDeepLinked",
						params: { deepLink: deeplink},
					});
					this.$store.commit("setDeepLink", deeplink);
				} else if ( model.programData.length !== 0 && this.$router.currentRoute.path !== "/") {
					this.$router.push({
						name: "home"
					});
					this.$store.commit("setDeepLink", "");
				}
			}
			model.programCount = 0;
			model.programGroups = {};
			results.forEach(function(program) {
				var showProgram = true;
				if(model.isExchange){
					showProgram = false;
					if (Object.keys(activeFilters).length > 1) {
						for (var filterKey2 in activeFilters) {
							if(filterKey2 != "studiejaar"){
								if(activeFilters[filterKey2]){
									if (!showProgram && activeFilters[filterKey2].includes(program[filterKey2])) {
										showProgram = true;
									}
								}
								
								if(filterKey2 == "Faculty") {
									program.Faculties.forEach((value) => {
										if(!showProgram && activeFilters[filterKey2].includes(value)) {
											showProgram = true;
										}
									});
								}
								if(filterKey2 == "ExchangeGroup") {
									program.ExchangeGroups.forEach((value) => {
										if(!showProgram && activeFilters[filterKey2].includes(value)) {
											showProgram = true;
										}
									});
								}
							}
							
						}
					} else {
						showProgram = true;
					}
				}else{
					if (Object.keys(activeFilters).length > 0) {
						for (var filterKey in activeFilters) {
							if(filterKey != "studiejaar"){
								if(activeFilters[filterKey]){
									if (showProgram && !activeFilters[filterKey].includes(program[filterKey])) {
										showProgram = false;
									}
								}
							}
							if(filterKey == "Faculty") {
								program.Faculties.forEach((value) => {
									if(!showProgram && activeFilters[filterKey].includes(value)) {
										showProgram = true;
									}
								});
							}
							if(!showProgram)
								break;
						}
					}
				}
				if (showProgram) {
					if (!model.programGroups[program.Group]) {
						model.programGroups[program.Group] = [];
					}
					model.programGroups[program.Group].push(program);

					model.programCount++;
					model.filterKeys.forEach(function(key) {
						var programElement = program[key];
						if(!programElement){
							return; // Value is empty, so don't add it to the count
						}

						var filterElement = model.filters[key];
						filterElement[programElement].Count++;

						if(key == "Faculty") {
							program.Faculties.forEach((value) => {
								if(value != programElement) {
									var filterElement = model.filters[key];
									filterElement[value].Count++;
								}
							});
						}
					});
					if(model.isExchange){
						fdmciGroup = program.ExchangeGroup;
						if(fdmciGroup){
							model.fdmciGroups[fdmciGroup].Count++;
						}
						program.ExchangeGroups.forEach((value) => {
							if(value != fdmciGroup)
								model.fdmciGroups[value].Count++;
						});
					}
				}
			});

			var sortedGroups = {};
			Object.keys(model.programGroups)
				.sort((a,b) => (a.toLowerCase() > b.toLowerCase()) ? 1 : ((b.toLowerCase() > a.toLowerCase()) ? -1 : 0))
				.forEach(function(key) {
					sortedGroups[key] = model.programGroups[key];
					sortedGroups[key].sort((a,b) => (a.Title.toLowerCase() > b.Title.toLowerCase()) ? 1 : ((b.Title.toLowerCase() > a.Title.toLowerCase()) ? -1 : 0));
				});
			model.programGroups = sortedGroups;
		},
		isPreview(item){
			return item.EditorialState === "Publiceer alleen de titel";
		},
		itemHTML(item) {
			var model = this;
			var final = item.Title;

			//avoid console errors by escaping regex special characters
			var special = new RegExp(/[\+\*\?\^\$\.\[\]\{\}\(\)\|]/gi);
			var reg = new RegExp(
				model.searchString.replace(special, function(str) {
					return "\\" + str;
				}),
				"gi"
			);

			var boldify = function(str) {
				return str.replace(reg, function(str) {
					return model.stringIsNullOrEmpty(str) ? str : "<b>" + str + "</b>";
				});
			};

			if (reg.test(item.Title)) {
				final = boldify(final);
			} else {
				var subtext = [];
				for (var k in model.searchOptions.keys) {
					//["Title", "Group", "Faculty", "Variant", "Level"]
					var key = model.searchOptions.keys[k];
					if (key != "Title") {
						if (reg.test(item[key])) {
							subtext.push(boldify(item[key]));
						}
					}
				}
				final += "<br><small>" + subtext.join(", ") + "</small>";
			}
			return final;
		},
		refreshPage() {
			this.$router.push({
				name: "home",
				params: {},
			});
		},
		programRoute(item) {
			if (this.selectedYear == this.currentYear){
				return { name: "program-overview", params: { programSlug: item.Slug } };
			}else{
				return { name: "program-overview", params: { programSlug: item.Slug, acYear : this.selectedYear } };
			}
		},
		preventEnter(e) {
			if (event.keyCode == 13) {
				e.preventDefault();
				return false;
			}
		},
		programRouteForCourseSelector(item) {
			return { name: "CourseSelectorById", params: { programId: item.Id, academicYear: this.selectedYear } };
		},
		exceedanceClicked: function(details, programTitle, programSlug) {
			this.details = details;
			this.warningProgramTitle = programTitle;
			this.warningProgramSlug = programSlug;
			this.showExceedanceModal = true;
		},
		exportToExcel: async function() {
			var model = this;
			if (!this.downloading) {
				this.downloading = true;
				if (!this.programDetails) {
					var excelRows = [];
					var toPercentage = function(value) {
						if(value === undefined || value == 0) return "0%";
						return value.toFixed(2) + "%";
					};
					var rowPromise = function(program) {
						return new Promise(async resolve => {
							try {
								var status = await get(`/api/program/getProgramStatus/${program.Id}/${model.language}/${model.isExchange}`);
								resolve({
									[model.$t("programList.excel.name")]: program.Title,
									[model.$t("programList.excel.status")]: program.EditorialState,
									[model.$t("programList.excel.total")]: status.NumberTotal,
									[model.$t("programList.excel.created")]: toPercentage(status.PercentCreated),
									[model.$t("programList.excel.entered")]: toPercentage(status.PercentEnter),
									[model.$t("programList.excel.approved")]: toPercentage(status.PercentApprove),
									[model.$t("programList.excel.preview")]: toPercentage(status.PercentPreview),
									[model.$t("programList.excel.completed")]: toPercentage(status.PercentComplete)
								});
							} catch {
								//Do nothing. If a single call fails (for example because of stale programlist data) the export should still succeed
								console.log(`Skipped programId ${program.Id} in the export because the call failed`)
								resolve({});
							}
							
						});
					};
					model.programData.forEach(program => {
						if(model.userCanEditProgram(program)){
							excelRows.push(rowPromise(program));
						}
					});

					this.programDetails = await Promise.all(excelRows);
				}

				var worksheet = XLSX.utils.json_to_sheet(this.programDetails);
				var new_workbook = XLSX.utils.book_new();
				XLSX.utils.book_append_sheet(new_workbook, worksheet, "Export");
				XLSX.writeFile(new_workbook, "Opleidingen " + new Date().toLocaleString("nl-NL") + ".xls", {
					bookType: "xls"
				});
				this.downloading = false;
			}
		}
	}
};
</script>
<style lang="less" scoped>
#app {
	.container.courseselectortitle {
		padding-left: 10px;
		padding-right: 10px;
	}
	.exchangeWrapper {
		.list-group-item {
			color: #666;
			padding: 8px;
			padding-left: 43px;
		}
	}
	.home {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.5;
		color: #666;
		cursor: default;
	}

	.header {
		font-family: "OpenSans Regular", "Segoe UI Semilight", "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 0.03em;
		line-height: 1.25;
		margin-top: 11px;
		margin-bottom: 11px;
	}

	.filter-header {
		color: #666;
		font-size: 1.15em;
	}

	.filters > li,
	.filters > div > li {
		color: #25167a;
		text-transform: uppercase;
		margin: 0px;
		margin-top: 5px;
		border: 0px solid;
		border-top: 1px solid #999;
		padding: 0px;
		padding-top: 8px;
		padding-bottom: 11px;
		position: relative;
		z-index: 1;
	}

	.subfilter {
		color: #666;
		text-transform: none;
		position: relative;
		display: flex;
		flex: 1 1 auto;
		justify-content: space-between;
		width: 100%;
		top: 4px;
		margin: 11px 0 5px 0;
	}

	.subfilter-form {
		flex: 1 1 100%;
	}

	.subfilter-label {
		color: #666;
		vertical-align: top;
		width: 100%;
	}

	.subfilter-label small {
		color: #999;
		margin-left: 0.25em;
	}

	.page-header {
		color: #25167a;
		font-size: 26px;
		margin-top: 37px;
	}

	.introduction p {
		color: #000;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.6;
	}

	.introduction a {
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		font-size: 15px;
		font-weight: 400;
		line-height: 1.6;
	}

	.search-text {
		padding: 2px 5px;
		height: 38px !important;
		font-size: 1rem;
		border: 1px solid #ced4da !important;
		border-radius: 3px 0 0 3px !important;
		border-right: none !important;
	}

	.programs-header {
		color: #666;
		font-size: 1.15em;
		font-weight: 400;
	}

	.program-group {
		color: #666;
		font-weight: bold;
		padding-left: 8px;
	}

	.program-groups > li {
		color: #666;
		border-top: solid 1px #ddd;
		padding: 0px;
		padding-top: 8px;
	}

	.programs {
		color: #666;
	}

	.redactiestatusOpleiding {
		margin-top: 4px;
		display: inline-block;
	}

	.program-group {
		padding-bottom: 8px;
		display: inline-block;
	}

	.programs > li {
		color: #666;
		padding: 8px;
		padding-left: 43px;
		border-top: solid 1px #ddd !important;
	}

	.program {
		padding-left: 26px;
		margin-left: -26px;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		color: #666;
		text-decoration: none;
	}
	.program-icon {
		color: #666;
		font-size: 22px;
		vertical-align: text-top;
	}
	.program-link {
		padding-left: 26px;
		margin-left: -26px;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		color: #666;
		text-decoration: none;
	}

	.program-link-icon {
		color: #666;
		font-size: 22px;
		vertical-align: text-top;
	}

	.program-link-icon:hover {
		color: #666;
	}

	.program-link:hover {
		text-decoration: underline;
		color: #666;
	}

	.program-link ::v-deep b {
		color: #444;
		font-weight: bold;
	}

	.program-link ::v-deep small {
		color: #888;
		padding-left: 1em;
	}

	.program-link ::v-deep small:hover {
		text-decoration: none;
		color: #888;
		cursor: default;
	}
    div.subfilter div.form-check input, label {
    cursor: pointer;
	}

	/* IE10+ only */
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		.hva-caret-right::before {
			margin-left: 0px;
		}
	}
}

span.bi-statistics,
div.download-excel,
div.shareEditor {
    background-color: #007C68;
    padding: 2px 3px 4px 4px;
    border-radius: 3px;
    color: #fff;
    float: right;
	margin-top: 12px;
	margin-left: 8px;
	cursor:pointer;
	width:28px;
	text-align: center;
}

h2.header.page-header {
    display: inline-block;
    width: auto;
}

.show-chart {
		margin-bottom: -1px;
	}

</style>