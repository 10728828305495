<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper" @click="$emit('close')">
				<div class="modal-container" @click.stop>
					<div class="modal-header">
						<h2>{{$t('programList.warning.header')}}</h2>
					</div>
					<div>
						<h2 class="smaller">{{$t('commonLabels.programme2')}}</h2>
						<template v-if="details.ProgramExceedances.length > 0">
							<div style="text-align:left;">
								<h2 class="smaller">
									<router-link :to="'/' + slug + '/' + acYear">{{title}}</router-link>
								</h2>
							</div>
							<ul>
								<li v-for="(item, index) in details.ProgramExceedances" :key="index" class="left">
									{{item}}
								</li>
							</ul>
						</template>

						<h2 class="smaller">{{$t('commonLabels.baseItems')}}</h2>
						<template v-if="details.BaseItemExceedances.length > 0">
							<template v-for="baseItem in details.BaseItemExceedances">
								<div :key="baseItem.Id">
									<div class="left">
                                        <h3 class="left small">
                                            <router-link :to="/co/ + slug +'/'+ baseItem.FirstPhasingValue +'/'+ baseItem.BaseItemId.substring(4) + '/' + acYear">{{baseItem.Title}}</router-link>
                                        </h3>
									</div>
									<ul>
										<li v-for="item in baseItem.CharacterExceedances" :key="item" class="left">
											{{item}}
										</li>
									</ul>
								</div>
							</template>
						</template>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<button class="modal-default-button greenButton" @click="$emit('close')" type="button">{{$t('general.ok')}}</button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>
<script>
export default {
	name: "warningModal",
	props: ["details", "title", "slug", "acYear"]
};
</script>
<style lang="less" scoped>
    #app{
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	max-width: 1000px;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	max-height: 80vh;
	margin-top: 10%;
	margin-bottom: 10%;
	overflow-y: auto;
}

.modal-body {
	margin: 20px 0;
}

.modal-default-button {
	float: right;
}

/*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */

.modal-enter {
	opacity: 0;
}

.modal-leave-active {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

h2 {
	color: #575757;
	font-size: 30px;
	text-align: center;
	font-weight: 600;
	text-transform: none;
	position: relative;
	margin: 0;
	padding: 0;
	line-height: 60px;
	display: block;
	font-family: "OpenSans Regular", "Segoe UI Semilight", "Segoe UI", "Segoe", Tahoma, Helvetica, Arial, sans-serif;
}

h2.smaller {
	text-align: left;
	font-size: 18px;
}

h3 a {
	color: #25167a;
}
.left {
	text-align: left;
}

.greenButton {
	background-color:#007C68;
	border-left-color:#007C68;
	border-right-color: #007C68;
	color: #fff;
	border: 0;
	box-shadow: none;
	font-size: 17px;
	font-weight: 500;
	border-radius: 5px;
	padding: 10px 32px;
	margin: 0 5px;
	cursor: pointer;
}

h3.small {
	text-transform: none;
	font-size: 11pt;
	line-height: 1.6em;
	font-weight: normal;
}
}
</style>
