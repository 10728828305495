<template>
	<div :class="['wrapper course-search', $i18n.locale]">
		<div class="close" v-if="!closed && baseItemCount > 0" @click="closed = true; searchQuery=''">
			<span>{{$t('courseSearch.close')}}</span>
			<font-awesome-icon far icon="times-circle"/>
		</div>
		<div class="search-input">
			<span class="desc">{{$t('general.searchFor')}}</span>
			<b-input-group class="input-group mb-3 search2">
				<b-form-input class="search-text" type="text" v-model="searchQuery" maxlength="2048" @keyup.enter="search" />
				<button type="button" class="search-button" @click="search"><font-awesome-icon icon="search"/></button>
			</b-input-group>
		</div>
		<div v-show="baseItemCount !== null && baseItemCount >= 0 && !closed">
			<div  class="course-counter">{{baseItemCount}} {{$t('courseSearch.coursesFound')}}</div>
			<template v-if="baseItemCount !== null && baseItemCount > 0">
				<table  class="search-table">
					<thead>
						<tr>
							<th class="">{{$t('general.title')}}</th>
							<th>{{$t('commonLabels.catalogueNumber')}}</th>
							<th class="">{{$t('commonLabels.programme2')}}</th>
							<th class="filter-select">{{$t('commonLabels.studyYear')}}</th>
							<th>{{$t('commonLabels.period')}}</th>
							<th class="button-column sorter-false"></th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(baseItem, index) in baseItems" :key="index">
							<td>
								<router-link :to="courseRoute(baseItem)">{{baseItem.Title}}</router-link>
							</td>
							<td>{{baseItem.CourseCatalogItem.CatalogueNumber}}</td>
							<td>
								<router-link :to="programRoute(baseItem)">{{baseItem.Program}}</router-link>
							</td>
							<td>{{baseItem.CourseCatalogItem.AcademicYear}}</td>
							<td class="block-grid"><span v-for="(grouping, index2) in baseItem.Groupings" :key="index2">{{grouping}}</span></td>
							<td>
								<div>
									<font-awesome-icon v-if="userCanEditCourse(baseItem)" :class="[ToCamelCase(baseItem.CourseCatalogItem.EditorialState), 'redactiestatusOpleiding right search-fontawesome']" :alt="stateMessage(baseItem.CourseCatalogItem.EditorialState)" :title="stateMessage(baseItem.CourseCatalogItem.EditorialState)" icon="circle" />
									<font-awesome-icon v-if="baseItem.CourseCatalogHasMultipleBaseItems" :alt="$t('general.sharesData')" :title="$t('general.sharesData')" icon="link" />
									<font-awesome-icon v-if="!stringIsNullOrEmpty(baseItem.CourseCatalogItem.Cloned)" class="right search-fontawesome" :alt="$t('general.cloned')" :title="$t('general.cloned')" icon="clone" />
								</div>
							</td>
						</tr>
					</tbody>
				</table>
				<div id="pager" class="pager">
					<form>
						<font-awesome-icon icon="angle-double-left" class="first" />
						<font-awesome-icon icon="angle-left" class="prev" />
						<!-- the "pagedisplay" can be any element, including an input -->
						<span class="pagedisplay" data-pager-output-filtered="{startRow:input} &ndash; {endRow} / {filteredRows} of {totalRows} total rows"></span>
						<font-awesome-icon icon="angle-right" class="next" />
						<font-awesome-icon icon="angle-double-right" class="last" />
						<select class="pagesize">
							<option value="10">10</option>
							<option value="20">20</option>
							<option value="30">30</option>
							<option value="40">40</option>
							<option value="all">{{$t('courseSearch.allRows')}}</option>
						</select>
					</form>
				</div>
			</template>
		</div>
		<spinner v-show="isLoading" />
	</div>
</template>
<script>
/*eslint-disable */
import EditorialState from "../mixins/EditorialState";
import Spinner from "../components/General/Spinner.vue";
import { get, camelCase} from "common/store/storeFunctions";
var $ = require("jquery");
require("tablesorter");
var tablesorterPager = require("tablesorter/dist/js/extras/jquery.tablesorter.pager.min.js");

import "tablesorter/dist/css/theme.default.min.css";

export default {
	name: "coursesearch",
	components:{
		Spinner
	},
	mixins: [EditorialState],
	data: function() {
		return {
			baseItems: null,
			searchQuery: "",
			exchangeProgram: "false",
			isLoading: false,
			closed: false
		};
	},
	computed: {
		userCanEditCourses(){
			return  (this.userIsCourseEditor || this.userIsCourseFinalEditor);
		},
		baseItemCount(){
			if(!this.baseItems){
				return null;
			}
			return this.baseItems.length;
		}
	},
	async created() {
		var model = this;
		if (location.host.indexOf("exchange") != -1) {
			model.exchangeProgram = "true";
		} 
	},
	methods: {
		search: async function() {
			var model = this;
			if(model.searchQuery === "") return;
			model.isLoading = true;
			model.closed = false;
			model.baseItems = await get(`/api/coursecatalog/getbyquery/${model.$i18n.locale == 'nl' ? 1043 : 1033}/${model.searchQuery}/${model.$parent.selectedYear}/${model.exchangeProgram}`);
			model.initializeTablesorter();
			model.isLoading = false;
		},
		courseRoute(baseitem) {
			return { name: "course-overview", params: { programSlug: baseitem.ProgramSlug, phaseValue: baseitem.FirstPhasingValue, baseItemId: baseitem.BaseItemId.substring(4), acYear: baseitem.AcademicYear } };
		},
		programRoute(baseitem) {
			return { name: "program-overview", params: { programSlug: baseitem.ProgramSlug, acYear : baseitem.AcademicYear } };
		},
		userCanEditCourse(baseItem){
			if(baseItem === undefined || baseItem === null){
				return false;
			}
			var facultyId = baseItem.FacultyId;
			var facultyBaseItemEditor = false;
			if (this.isExchange && ((this.roles.ExchangeProgramCourseEditor && this.roles.ExchangeProgramCourseEditor.indexOf(facultyId) != -1) || (this.roles.ExchangeProgramCourseFinalEditor && this.roles.ExchangeProgramCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;	
			}
			if (!this.isExchange && ((this.roles.CourseCatalogCourseEditor && this.roles.CourseCatalogCourseEditor.indexOf(facultyId) != -1) || (this.roles.CourseCatalogCourseFinalEditor && this.roles.CourseCatalogCourseFinalEditor.indexOf(facultyId) != -1))){
				facultyBaseItemEditor = true;				
			}
            return facultyBaseItemEditor && baseItem.Editable && this.userCanEditCourses
		},
		initializeTablesorter: function() {
			var model = this;
			this.$nextTick(() => {
				$("table.search-table").tablesorter({
					widgets: ["zebra", "pager", "filter"],
					widthFixed: false,
					// widgetOptions:{
					// 	filter_defaultFilter : {}
					// }
				})
				.tablesorterPager({
					container: $(".pager"),
					output: `{startRow} ${model.$t('courseSearch.to')} {endRow} ({totalRows}) ${model.$t('courseSearch.rows')}`,
				});
			})
		},
        ToCamelCase(item){
            return camelCase(item);
        }
	}
};
</script>
<style lang="less" scoped>
@import "../assets/border.less";
.wrapper{
	.snippet("", #007C68);
	&.nl::before {
		content: "Redacteur optie";
	}
	&.en::before {
		content: "Editor option";
	}
}

#app {
	td a {
		display: initial;
	}
}
.close {
	cursor: pointer;
}

.course-search .close {
    margin-top: 10px;
    color: #25167a;
    opacity: 1;
    font-size: 15px;
    font-weight: normal;
}

.course-search .close span {
    margin-right: 2px
}

.course-search .desc {
    padding: 10px 0;
    display: inline-block;
}
.course-search th {
	text-transform: capitalize;
}
.course-search .tablesorter-default tr.odd>td {
    background-color: #fff;
}
.course-search .tablesorter-default tr.even>td {
    background-color: #fafafa;
}
.course-search .pagedisplay {
    margin: 0 5px
}

.course-search .pagesize {
    margin-left: 10px;
}

.pager form {
    text-align: center;
}
.course-search .close {
    margin-top: 10px;
    color: #25167a;
    opacity: 1;
    font-size: 15px;
    font-weight: normal;
}

.course-search .close span {
    margin-right: 2px
}

.course-search .desc {
    padding: 10px 0;
    display: inline-block;
}
.course-search th {
	text-transform: capitalize;
}
.course-search .tablesorter-default tr.odd>td {
    background-color: #fff;
}
.course-search .tablesorter-default tr.even>td {
    background-color: #fafafa;
}
.course-search .pagedisplay {
    margin: 0 5px
}

.course-search .pagesize {
    margin-left: 10px;
}

.pager form {
    text-align: center;
}
</style>