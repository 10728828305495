export default {
  methods: {
    stateMessage(state) {
      if (state === "Invoeren") return this.$t("editPageLabels.state_enter");
      else if (state === "Goedkeuren") return this.$t("editPageLabels.state_approve");
      else if (state === "Gereed") return this.$t("editPageLabels.state_complete");
      else if (state === "Publiceer alleen de titel") return this.$t("editPageLabels.state_preview");
      else return this.$t("editPageLabels.state_unknown");
    }
  }
}
