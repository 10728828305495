<template>
        <ul class="statusContainer">
            <li class="statusRow">
				<router-link class="program-link hva-caret-right" :to="$parent.programRoute(program)">{{program.Title}}</router-link>
				<font-awesome-icon icon="exclamation-triangle" v-if="showWarningIcon" @click="$emit('exceedanceClicked', details, program.Title, program.Slug)" :alt="$t('statusRow.showExceedance')" :title="$t('statusRow.showExceedance')" />
				<font-awesome-icon :class="[programState, 'redactiestatusOpleiding right']" :alt="stateMessage(program.EditorialState)" :title="stateMessage(program.EditorialState)" icon="circle" />
				<font-awesome-icon v-if="!isLoading" icon="chart-bar" class="right fontAwesome" :title="$t('statusRow.editorialStateText')" :alt="$t('statusRow.editorialStateText')" @click="toggleDetails" />
				<font-awesome-icon v-else icon="sync" class="right" spin />
            </li>
            <transition name="fade" tag="ul">
                <li v-if="showDetails && !objectIsNull(details)" class="bar_totaal">
                    <template>
                        <p>{{$t('statusRow.created')}} {{round(details.PercentCreated)}}%</p>
                        <b-progress :value="details.PercentCreated" :max="100" class="mb-3" variant="dark"></b-progress>
                        <p>{{$t('statusRow.enter')}} {{round(details.PercentEnter)}}%</p>
                        <b-progress :value="details.PercentEnter" :max="100" class="mb-3" variant="danger"></b-progress>
						<p>{{$t('statusRow.preview')}} {{round(details.PercentPreview)}}%</p>
                        <b-progress :value="details.PercentPreview" :max="100" class="mb-3" variant="warning"></b-progress>
                        <p>{{$t('statusRow.approve')}} {{round(details.PercentApprove)}}%</p>
                        <b-progress :value="details.PercentApprove" :max="100" class="mb-3" variant="warning"></b-progress>
                        <p>{{$t('statusRow.completed')}} {{round(details.PercentComplete)}}%</p>
                        <b-progress :value="details.PercentComplete" :max="100" class="mb-3" variant="success"></b-progress>
                        <p>{{$t('statusRow.totalNumberOfCoursesText')}} {{details.NumberTotal}}</p>
                    </template>
                </li>
            </transition>
        </ul>
</template>
<script>
import EditorialState from "common/mixins/EditorialState.js";
import { get, camelCase } from "common/store/storeFunctions";

export default {
	name: "StatusRow",
	mixins: [EditorialState],
	props: ["program", "bus"],
	data: function() {
		return {
			details: null,
			showDetails: false,
			isLoading: false,
			programState: "",
		};
	},
	computed: {
		showWarningIcon() {
			return !this.objectIsNull(this.details) && (this.details.ProgramExceedances.length > 0 || this.details.BaseItemExceedances.length > 0);
		}
	},
	mounted() {
		var model = this;
		model.bus.$on("showDetails", function() {
			model.showDetails = false;
			model.toggleDetails();
		});
		model.programState = camelCase(model.program.EditorialState)
	},
	methods: {
		toggleDetails: async function() {
			var model = this;
			if (model.objectIsNull(model.details)) {
				model.isLoading = true;
				model.details = await get(`/api/program/getProgramStatus/${model.program.Id}/${model.language}/${model.isExchange}`);
				model.isLoading = false;
			}
			model.showDetails = !model.showDetails;
		},
		round: function(value) {
			if (value === undefined || value == 0) return 0;
			return Math.round(value * 100) / 100;
		}
	}
};
</script>

<style lang="less" scoped>
  .program-link {
		padding-left: 26px;
		margin-left: -26px;
		font-family: "Open Sans", Helvetica, Arial, sans-serif;
		color: #666;
		text-decoration: none;
	}

	.program-link-icon {
		color: #666;
		font-size: 22px;
		vertical-align: text-top;
	}

	.program-link-icon:hover {
		color: #666;
	}

	.program-link:hover {
		text-decoration: underline;
		color: #666;
	}

	.program-link ::v-deep b {
		color: #444;
		font-weight: bold;
	}

	.program-link ::v-deep small {
		color: #888;
		padding-left: 1em;
	}

	.program-link ::v-deep small:hover {
		text-decoration: none;
		color: #888;
		cursor: default;
	}
	.bar_totaal{
		padding-top:5px;
		margin-top:7px;
		border-top: 1px solid #ddd;
	}
	.bar_totaal p {
		margin-bottom: 2px;
	}
	#app .bar_totaal .mb-3.progress {
		margin-bottom: 10px !important;
	} 
	#app .progress{
		height: 10px;
	}
	.statusContainer{
		padding-left: 0;
	}
</style>